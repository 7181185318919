// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-input::placeholder {
    color: black;
    font-size: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/components/TextInput/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".my-input::placeholder {\n    color: black;\n    font-size: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
